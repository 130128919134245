<template>
  <div class="bg">
    <div class="headerBox">
      <div class="logo">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="navList">
        <div class="item" @click="handlNavClick(item)" v-for="item in navList" :key="item.id">
          {{ item.label }}
          <!-- v-if="navActive == item.id" -->
          <span :class="navActive == item.id ? 'line' : 'base'"></span>
        </div>
      </div>
      <div class="language">
        <div class="languageIcon" @click="handleSelectLanguage">
          <img src="@/assets/language.png" alt="">
        </div>

        <ul v-if="showLanguage">
          <li label="中文" value="zh" @click="handleSelect('zh')">{{ $t('message.chinese') }}</li>
          <li label="英文" value="en" @click="handleSelect('en')">{{ $t('message.english') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, watch } from 'vue'
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";
const { locale } = useI18n(); //当前
const router = useRouter();
const language = ref('zh')
const { $t } = getCurrentInstance().proxy;
const showLanguage = ref(false)
const navList = ref([])
const navActive = ref(1)
const initMenuData = () => {
  navList.value = [
    {
      label: $t('message.home'),
      path: '/',
      id: 1,
      active: false,
    },
    {
      label: 'FAQ',
      path: '/faq',
      id: 2,
      active: false,
    }
  ]
}
watch(locale, () => {
  initMenuData();
});
initMenuData();
function handlNavClick(item) {
  navActive.value = item.id
  router.push({ path: item.path })
}

function handleSelectLanguage() {
  showLanguage.value = !showLanguage.value
}
const handleSelect = (value) => {
  locale.value = value;
  language.value = value
  showLanguage.value = false
};

</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  background: linear-gradient(0deg, #010A22 0%, #08163B 100%);
  box-shadow: 0px 1px 0px 0px #1A2D60;
  // overflow: hidden;
  height: 120px;
  position: absolute;
  z-index: 99;
  top: 0;
}

.headerBox {
  width: calc(100vw - 720px);
  height: 120px;

  margin: 0 360px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 147px;

    img {
      margin: 38px 0;
      width: 147px;
      height: 44px;
    }
  }

  .navList {
    display: flex;
    align-items: center;
    padding: 38px 0;
    width: 400px;

    .item {
      // margin: 38px 0;
      text-align: center;
      font-weight: bold;
      font-size: 22px;
      color: #E4E4E4;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .base,
      .line {
        display: block;
        width: 16px;
        height: 6px;
        border-radius: 3px;
        margin: 4px auto 0;
      }

      .line {
        background: linear-gradient(-90deg, #1C7ED4 0%, #50D2DC 100%);
      }

      .base {
        background: transparent;
      }
    }

    .item:not(:first-child) {
      margin-left: 60px;
    }
  }

  .language {
    width: 80px;

    .languageIcon {
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        margin: 49px auto;
        width: 22px;
        height: 22px;
        display: block;
        cursor: pointer;
      }
    }

    ul {
      position: absolute;
      background: #fff;
      width: 200px;
      height: 140px;
      line-height: 25px;
      list-style-type: none;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.8);

      li {
        font-size: 14px;
        color: #333;
        cursor: pointer;
        height: 70px;
        line-height: 70px;
        padding-left: 10px;
        font-weight: 500;
      }

      li:hover {
        color: rgb(28, 126, 212);
        background: rgba(28, 126, 212, 0.3);
      }
    }
  }
}
</style>