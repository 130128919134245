import { createI18n } from "vue-i18n";
import en from './en'
import zh from './zh';

const i18n = createI18n({
  locale: localStorage.getItem('language') || 'zh', // 默认是中文
  globalInjection: true,//全局配置$t
  legacy: false,
  messages: { en, zh },// 需要做国际化的语种
  warnHtmlMessage: false
})

export default i18n
