<template>
  <div class="footerT">
    <div class="logo">
      <img :src="require('@/assets/logo.png')" alt="">
    </div>
    <div class="connect">
      <img :src="require('@/assets/tel.png')" alt="">
      <img :src="require('@/assets/tel.png')" alt="">
      <img :src="require('@/assets/t.png')" alt="">
    </div>
  </div>
  <div class="footerB">© 2024 explorer3 I All rights reserved. </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.footerT {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 64px 0;
  margin: 0 360px;
  border-top: 1px solid #1A2D60;
  border-bottom: 1px solid #1A2D60;

  .logo {
    img {
      width: 274px;
      height: 82px;
    }
  }

  .connect {
    img {
      width: 45.5px;
      height: 45.5px;
      display: inline-block;
      margin-right: 30px;
      cursor: pointer;
    }
  }
}

.footerB {
  text-align: center;
  padding: 40px 0;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #AFAFAF;
}
</style>