export default {
  message: {
    home: '首页',
    chinese: '中文',
    english: '英文',
    stage: '阶段',
    price: '价格',
    account: '额度(e3)',
    open: '释放',
    toObject: '对象',
  },
  p1: {
    t1: '在超级AI到来之前，人机协作将会是人们工作中的常态，基于scaling law的大模型由智能助理逐渐转向工作伙伴关系，承担越来越多的工作内容。',
    t2: '实现这一目标，需要大幅改进场景效率，agent是一种执行层解决方案，高效率处理垂直领域的问题。'
  },
  p2: {
    t1: `将应用生成标准化向量数据库的过程，称作应用图谱。`,
    t2: `它包含三个特性：极简化、标准化、代币化，以实现数据互通相、业务组合及商业化。我们正在尝试创建这种标准化方案，`,
    t3: `在区块链上生成应用版权，并结合transformer语言模型来实现模块的精准执行调用。`,
  },
  p3: {
    t1: `面向大模型的agent市场，用户无需下载应用，无需注册，甚至不需要交互，通过e3窗口即可与智能体对话，实现多业务执行、多场景协同。在这个隐形的agent市场，开发者贡献了所有你想要的服务，并把它融入到大模型。`,
    t2: `每个agent都有独立的功能代币，使用agent时用于支付版权费用，独有的版权资产（nft）和代币收入激励开发者创建更高效的agent以提高收益。`,
    t3: `海量的agent会产生无数的场景组合，创造出丰富的商业需求。用户输入文字，e3协同执行任务，从txt 到exe ，e3模型的能力会发生质的飞跃。它将具备高度的智能、更低的开销和全局规划能力。All-in-one ，把每一个智能体模块，融入到执行层网络，我们离AGI更进一步！`,
  },
  p4: {
    t1: `代币支付版权费用，形成正向的商业价值`,
    l1: `用户`,
    d1: `应用操作时间节省80％`,
    l2: `AI网络`,
    d2: `执行精准度提升90％`,
    l3: `开发者`,
    d3: `创建agent的成本降低 99%`,
  },
  p5: {
    t1: `标准化智能体 chat+X`,
    t2: `智能体执行链上业务  chat + Defi`,
    t3: `智能体执行链下业务 chat+SaaS`,
    t4: `多智能体协同  chat + X + Y + Z`,
  },
  p6: {
    t1: `第一阶段进度`
  },
  r1: {
    t1: `代币总发行：100B，代币用于支付、结算、质押。代币分配如下图：`,
    l1: '基金',
    l2: '融资',
    l3: '激励'
  },
  r2: {
    time1: '第一阶段',
    open1: '133天后，每天释放0.1 %',
    obj1: '基金/社群基金，5eth起投',
    time2: '第二阶段',
    open2: '--',
    obj2: '--',
    time3: '第三阶段',
    open3: '--',
    obj3: '--',
    desc: `当前只接受基金、社群类基金投资，你可以`,
    objectApply: '申请资格',
    contactUs: '联系我们',
    or: '或者'
  },
  r3: {
    t1: `基金透过孵化池预计投资60万个智能体`,
    t2: `投资收益中80% 分配给捐赠者`,
    t3: `资金间接进入各个应用场景加速生态发展`,
  },
  title: {
    t1: `发行`,
    t2: `融资`,
    t3: `基金`,
  }
}