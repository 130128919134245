<template>
  <div class="section-two">
    <div class="title">Agent market</div>
    <div class="world">
      <span>
        {{ $t('p3.t1') }}
      </span>
      <br />
      <br />
      <span>
        {{ $t('p3.t2') }}
      </span>
    </div>
    <div class="bottom-box">
      <div class="infoImg">
        <img :src="require('@/assets/infoThree.png')" alt="" />
      </div>
      <div class="descBox">
        <div class="desc-title">AI execution network</div>
        <div class="desc-info">{{ $t('p3.t3') }}</div>
      </div>
    </div>
    <div class="imgBtm">
      <img class="moon" :src="require('@/assets/halfMoon.png')" alt="" />
      <img class="down" :src="require('@/assets/down.png')" alt="" />
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.section-two {
  overflow: hidden;

  .title {
    margin-top: 80px;
    font-family: Arial Black;
    font-weight: 400;
    font-size: 58px;
    color: #FFFFFF;
    text-align: center;
  }

  .world {
    margin: 30px 360px;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 22px;
    color: #E5E5E5;
    line-height: 36px;
  }

  .bottom-box {
    margin: 150px 360px 30px;
    display: flex;
    align-items: center;

    .infoImg {
      img {
        width: 549px;
        height: 422px;
      }
    }

    .descBox {
      .desc-title {
        white-space: nowrap;
        font-family: Arial Black;
        font-weight: 400;
        font-size: 58px;
        color: #FFFFFF;
      }

      .desc-info {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 22px;
        color: #E5E5E5;
        margin: 30px 30px 0;
      }
    }
  }

  .imgBtm {
    width: 100%;
    overflow: hidden;
    position: relative;

    .moon {
      width: 1920px;
      height: 285px;
    }

    .down {
      width: 181px;
      height: 181px;
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>