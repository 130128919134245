<template>
  <div class="section-two">
    <div class="title">Business ECO</div>
    <div class="world">
      <span>
        {{ $t('p4.t1') }}
      </span>
    </div>
    <!-- v-if="list && list.length > 0" -->
    <div class="infoImg">
      <div class="itemInfo" v-for="item in list" :key="item.number" :style="{ borderColor: item.color }">
        <div class="circleBox">
          <Circle :key="item.number" :idType="item.id" :percentage="item.number" :size="150" :color="item.color"
            trackColor="#ddd" />
        </div>
        <div class="text">
          <div class="title1" :style="{ color: item.color }">{{ item.title }}</div>
          <div>{{ item.desc }}</div>
        </div>
      </div>
      <!-- <div class="itemInfo" :style="{ borderColor: list[0].color }">
        <div class="circleBox">
          <Circle key=1 :idType="'main1'" :percentage="80" :size="150" :color="'#1351F0'" trackColor="#ddd" />
        </div>
        <div class="text">
          <div class="title1" :style="{ color: list[0].color }">{{ $t('p4.l1') }}</div>
          <div>{{ $t('p4.d1') }}</div>
        </div>
      </div> -->
      <!-- <div class="itemInfo" :style="{ borderColor: list[1].color }"> -->
      <!-- <div class="circleBox">
          <Circle :key="list[1].number" :idType="list[1].id" :percentage="list[1].number" :size="150" :color="list[1].color"
            trackColor="#ddd" />
        </div>
        <div class="text">
          <div class="title1" :style="{ color: list[1].color }">{{ list[1].title }}</div>
          <div>{{ list[1].desc }}</div>
        </div>
      </div> -->
      <!-- <div class="itemInfo" :style="{ borderColor: list[2].color }">
        <div class="circleBox">
          <Circle :key="list[2].number" :idType="list[2].id" :percentage="list[2].number" :size="150" :color="list[2].color"
            trackColor="#ddd" />
        </div>
        <div class="text">
          <div class="title1" :style="{ color: list[2].color }">{{ list[2].title }}</div>
          <div>{{ list[2].desc }}</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, watch, onMounted } from 'vue'
import Circle from '@/components/Circle'
import { useI18n } from "vue-i18n";
const { locale } = useI18n(); //当前
const { $t } = getCurrentInstance().proxy;
const list = ref([])
// const list = ref([
//   {
//     title: $t('p4.l1'),
//     number: 80,
//     desc: $t('p4.d1'),
//     color: '#1351F0',
//     id: 'main1'
//   },
//   {
//     title: $t('p4.l2'),
//     number: 90,
//     desc: $t('p4.d2'),
//     color: '#61F3C3',
//     id: 'main2'
//   },
//   {
//     title: $t('p4.l3'),
//     number: 99,
//     desc: $t('p4.d3'),
//     color: '#EF089B',
//     id: 'main3'
//   }
// ])
const initListData = () => {
  list.value = [
    {
      title: $t('p4.l1'),
      number: 80,
      desc: $t('p4.d1'),
      color: '#1351F0',
      id: 'main1'
    },
    {
      title: $t('p4.l2'),
      number: 90,
      desc: $t('p4.d2'),
      color: '#61F3C3',
      id: 'main2'
    },
    {
      title: $t('p4.l3'),
      number: 99,
      desc: $t('p4.d3'),
      color: '#EF089B',
      id: 'main3'
    }
  ]
}
initListData();
watch(locale, () => {
  initListData();
});
</script>

<style lang="less" scoped>
.section-two {
  overflow: hidden;

  .title {
    margin-top: 80px;
    font-family: Arial Black;
    font-weight: 400;
    font-size: 58px;
    color: #FFFFFF;
    text-align: center;
  }

  .world {
    margin: 30px 360px;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 22px;
    color: #E5E5E5;
    line-height: 36px;
    text-align: center;
  }

  .infoImg {
    margin: 30px 360px 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .itemInfo {
      width: 405px;
      height: 219px;
      border-radius: 0 0 100px 0;
      border: 3px solid;
      color: #FFFFFF;
      margin: 0 6px;
      display: flex;
      align-items: center;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 20px;

      .circleBox {
        width: 150px;
        height: 150px;
      }

      .text {
        flex: 1;

        .title1 {
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 26px;
        }
      }
    }

    img {
      width: 1019px;
      height: 474px;
      margin: 0 auto;
      display: block;
    }
  }
}
</style>