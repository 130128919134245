export default {
  message: {
    home: 'home',
    chinese: 'Chinese',
    english: 'English',
    stage: 'Stage',
    price: 'Price',
    account: 'Quota(e3)',
    open: 'Release',
    toObject: 'Object',
  },
  p1: {
    t1: `Before the arrival of super AI, human-machine collaboration will be the norm in people's work.Large models based on scaling law will gradually shift from intelligent assistants to working partnerships, taking on more and more work content.`,
    t2: `To achieve this goal, it is necessary to greatly improve scene efficiency. Agent is an execution layer solution that efficiently handles problems in vertical fields.`,
  },
  p2: {
    t1: `The process of generating a standardized vector database from an application is called application mapping.`,
    t2: `It contains three characteristics: extreme simplification, standardization, and tokenization to achieve data interoperability, business combination, and commercialization. We are trying to create this standardized solution,`,
    t3: ` generate application copyrights on the blockchain, and combine it with the transformer language model to achieve precise execution of the module.`,
  },
  p3: {
    t1: `In the agent market for large models, users do not need to download applications, register, or even interact. They can talk to agents through the e3 window to achieve multi-business execution and multi-scenario collaboration. In this invisible agent market, developers contribute all the services you want and integrate it into the big model.`,
    t2: `Each agent has an independent functional token, which is used to pay copyright fees when using the agent. The unique copyright assets (NFT) and token income motivate developers to create more efficient agents to increase revenue.`,
    t3: `A large number of agents will generate countless scenario combinations and create rich business needs. The user inputs text, and e3 collaborates to perform tasks. From txt to exe, the capabilities of the e3 model will undergo a qualitative leap. It will have a high degree of intelligence, lower overhead and global planning capabilities. All-in-one, integrating each agent module into the execution layer network, we are one step closer to AGI!`,
  },
  p4: {
    t1: `Tokens pay copyright fees and create positive business value`,
    l1: `Users`,
    d1: `Save 80% on application operation time`,
    l2: `AI network`,
    d2: `Execution accuracy increased by 90%`,
    l3: `Developers`,
    d3: `The cost of creating an agent is reduced by 99%`,
  },
  p5: {
    t1: `standardized agent  chat+X`,
    t2: `The agent executes on-chain business  chat + Defi `,
    t3: `The agent executes off-chain business   chat+SaaS`,
    t4: `Multi-agent collaboration  chat + X+Y +Z`,
  },
  p6: {
    t1: `Phase one Progress`
  },
  r1: {
    t1: `Issuance: Total token issuance: 100B, tokens are used for payment, settlement, and pledge. The token distribution is as follows: 30% for funds, 30% for financing, and 40% for incentives.`,
    l1: `Funds`,
    l2: `Financing`,
    l3: `Incentives`,
  },
  r2: {
    time1: 'Phase One',
    open1: 'After 133 days, 0.1% will be released every day',
    obj1: 'Fund/community fund ，Starting from 5eth',
    time2: 'Phase Two',
    open2: '--',
    obj2: '--',
    time3: 'Phase Three',
    open3: '--',
    obj3: '--',
    desc: `Currently we only accept investments from funds and community funds. You can`,
    objectApply: 'apply for qualifications',
    contactUs: 'contact us',
    or: 'or'
  },
  r3: {
    t1: `The fund is expected to invest 600,000 smart agents through the incubation pool`,
    t2: `80% of investment income is distributed to donors`,
    t3: `Funds indirectly enter various application scenarios to accelerate ecological development`,
  },
  title: {
    t1: `Issue`,
    t2: `Financing`,
    t3: `Funds`,
  }
}