<template>
  <div class="section-two">
    <div class="title">Roadmap</div>
    <div class="mapList">
      <div class="mapItemBox" v-for="map in mapList" :key="map.id">
        <div class="imgUP" v-if="map.id == 2">
          <img :src="require('@/assets/onUp.png')" alt="">
        </div>
        <div class="mapItem">
          <div class="mapNumber">{{ map.id }}</div>
          <div class="mapTitle">{{ map.title }}</div>
          <div class="mapInfo">{{ map.info }}</div>
        </div>
        <div class="imgDown" v-if="map.id == 1 || map.id == 3">
          <img :src="require('@/assets/onDown.png')" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, watch } from "vue";
import { useI18n } from "vue-i18n";
const { locale } = useI18n(); //当前
const mapList = ref([])
const { $t } = getCurrentInstance().proxy;

const initMapList = () => {
  mapList.value = [
    {
      title: 'AppGraph',
      id: 1,
      info: $t('p5.t1')
    },
    {
      title: 'Web3',
      id: 2,
      info: $t('p5.t2')
    },
    {
      title: 'SaaS',
      id: 3,
      info: $t('p5.t3')
    },
    {
      title: 'Partner',
      id: 4,
      info: $t('p5.t4')
    },
  ]
};
initMapList();
watch(locale, () => {
  initMapList();
});
</script>

<style lang="less" scoped>
.section-two {
  overflow: hidden;
  background: #0E172E;

  .mapList {
    margin: 60px 360px;
    color: #FFFFFF;
    display: flex;

    .mapItemBox {
      .mapItem:hover {
        background: #1351F0;
      }

      .mapItem {
        width: 326px;
        height: 220px;
        background: #0E172E;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .mapNumber {
          position: absolute;
          width: 54px;
          height: 54px;
          line-height: 54px;
          text-align: center;
          top: -27px;
          left: 27px;
          background: #0E172E;
          border-radius: 50%;
          border: 4px solid #1351F0;

        }


        .mapTitle {
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 34px;
          color: #FFFFFF;
        }

        .mapInfo {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 20px;
          color: #E4E4E4;
          text-align: center;
        }
      }

      .imgDown,
      .imgUP {
        margin: 10px;

        img {
          width: 60px;
          // height: 55px;
          margin: 0 auto;
          display: block;
        }
      }
    }

    .mapItemBox:nth-child(2) {
      margin-left: -40px;
      margin-top: 110px;
    }

    .mapItemBox:nth-child(3) {
      margin-left: -40px;
      margin-bottom: 110px;
    }

    .mapItemBox:nth-child(4) {
      margin-left: -40px;
      margin-top: 160px;
    }
  }

  .title {
    margin-top: 80px;
    font-family: Arial Black;
    font-weight: 400;
    font-size: 58px;
    color: #FFFFFF;
    text-align: center;
  }

}
</style>