<template>
  <div class="section-two">
    <div class="title">AppGraph</div>
    <div class="world">
      <span>
        {{ $t('p2.t1') }}
        <br />
        <br />
        {{ $t('p2.t2') }}
        <br />
        {{ $t('p2.t3') }}
      </span>
    </div>
    <div class="infoImg">
      <img :src="require('@/assets/infoTwo.png')" alt="">
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.section-two {
  overflow: hidden;
  background: #0E172E;

  .title {
    margin-top: 80px;
    font-family: Arial Black;
    font-weight: 400;
    font-size: 58px;
    color: #FFFFFF;
    text-align: center;
  }

  .world {
    margin: 30px 360px;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 22px;
    color: #E5E5E5;
    line-height: 36px;
  }

  .infoImg {
    margin: 30px auto;

    img {
      width: 1019px;
      height: 474px;
      margin: 0 auto;
      display: block;
    }
  }
}
</style>