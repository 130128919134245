<template>
  <div class="section-two">
    <div class="title">Financing</div>
    <div class="Financing">
      <div class="tips">{{ $t('p6.t1') }}</div>
      <div class="progressBox">
        <Progress />
      </div>
      <div class="trans">
        <div>total : 0.3b $e3 \ 1eth ≈ 300 000 e3</div>
        <div @click="handleToPage">rules >></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();

import Progress from "@/components/Progress.vue";
function handleToPage() {
  router.push('/rules');
}
</script>

<style lang="less" scoped>
.section-two {
  overflow: hidden;

  .Financing {
    margin: 50px 360px 90px;
    // height: 540px;

    .tips {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #E5E5E5;
    }

    .progressBox {
      margin: 20px auto;
    }

    .trans {
      display: flex;
      justify-content: space-between;

      div:nth-child(1) {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #FFFFFF;
      }

      div:nth-child(2) {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #1351F0;
        cursor: pointer;
      }
    }
  }

  .title {
    margin-top: 80px;
    font-family: Arial Black;
    font-weight: 400;
    font-size: 58px;
    color: #FFFFFF;
    text-align: center;
  }

}
</style>