<template>
  <div v-if="props.percentage == 80" ref="main1" id="main1"></div>
  <div v-if="props.percentage == 90" ref="main2" id="main2"></div>
  <div v-if="props.percentage == 99" ref="main3" id="main3"></div>
</template>

<script setup>
import * as echarts from 'echarts';
import { onMounted, defineProps } from 'vue';
const props = defineProps({
  percentage: Number,
  idType: String,
  color: String,
})
console.log(props);

onMounted(() => {
  const chart = echarts.init(document.getElementById(props.idType));
  var getvalue = [props.percentage];

  let option = {
    title: {
      text: getvalue + '%',
      textStyle: {
        color: '#fff',
        fontSize: 12,
      },
      // subtext: "综合得分",
      // subtextStyle: {
      //   color: "#666666",
      //   fontSize: 30,
      // },
      itemGap: 8,
      left: "center",
      top: "36%",
    },
    angleAxis: {
      max: 100,
      clockwise: true, // 逆时针
      // 隐藏刻度线
      show: false,
    },
    radiusAxis: {
      type: "category",
      show: true,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    polar: {
      center: ["50%", "50%"],
      radius: "100%", //图形大小
    },
    series: [
      {
        type: "bar",
        data: getvalue,
        showBackground: true,
        backgroundStyle: {
          color: "#010A22",// 环形背景
        },
        coordinateSystem: "polar",
        roundCap: true,
        barWidth: 8,
        itemStyle: {
          normal: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                // color: "#25BFFF",
                color: props.color,
              },
              {
                offset: 1,
                // color: "#5284DE",
                color: props.color,
              },
            ]),
            shadowBlur: 5,
            // shadowColor: "#2A95F9",
            shadowColor: props.color,
          },
        },
      },
    ],
  };
  chart.setOption(option);
});
</script>

<style lang="less" scoped>
#main1 {
  width: 150px;
  height: 150px;
}

#main2 {
  width: 150px;
  height: 150px;
}

#main3 {
  width: 150px;
  height: 150px;
}
</style>