<template>
  <!-- <div style="clear: both;overflow: hidden;"> -->
  <div class="sectionOne">
    <img class="bgImg" :src="require('@/assets/wind.png')" alt="">
    <div class="content">
      <div class="wordImg">
        <img :src="require('@/assets/word.png')" alt="">
      </div>
      <div class="btn-box">
        <div class="btn">Web3 Based</div>
      </div>
      <div class="text">
        <div>{{ $t('p1.t1') }}</div>
        <div>{{ $t('p1.t2') }}</div>
      </div>
      <div class="text-img">
        <img :src="require('@/assets/infoOne.png')" alt="" />
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script setup>

</script>

<style lang="less" scoped>
.sectionOne {
  width: 100%;
  height: inherit;
  // background: url(@/assets/wind.png);
  // position: relative;

  .bgImg {
    width: 656px;
    height: 621px;
    position: absolute;
    top: 45px;
    right: 360px;
    z-index: 10;
  }

  .content {
    width: calc(100vw - 720px);
    margin: 0 auto;

    .wordImg {
      margin-top: 133px;

      img {
        width: 1044px;
        height: 167px;
      }
    }

    .btn-box {
      margin-top: 57px;

      .btn {
        width: 240px;
        height: 68px;
        line-height: 68px;
        text-align: center;
        background: linear-gradient(-90deg, #1C7ED4, #50D2DC);
        border-radius: 34px;
        font-family: DIN Alternate;
        font-weight: bold;
        font-size: 34px;
        color: #FFFFFF;
      }
    }

    .text {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 22px;
      color: #E6E6E6;
      margin: 168px auto 60px;

      div:nth-child(1) {
        margin-bottom: 30px;
      }
    }

    .text-img {
      margin-bottom: 30px;

      img {
        width: 620px;
        height: 324px;
        margin: 0 auto;
        display: block;
      }
    }
  }
}
</style>